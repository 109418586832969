import React, { useRef, useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CBtnList,
  CSectTitle,
  CSectTitle03,
  CMedia,
  CHorizCard,
  CHeroImg,
  CCard,
  CGeneralCard03,
  CFaqList,
  CDefinition,
  LContact03,
  CPlanMedia03,
  LPlan,
  CFocalMedia,
  CStayPlanRecommend,
} from '../../../components/_index';
import infoChoice from '../../../utils/info-choice';
import infoGet from '../../../utils/info-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        type="ja"
        data={{
          title: {
            main: '法人企業のご利用について',
            sub: <>CORPORATE CUSTOMERS</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/corporation/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/corporation/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <section className="l_sect02 u_pt50">
        <LWrap>
          <p className="c_sectLead u_mb40">
            ロイヤルパークホテルでは、ご利用の機会が多い法人企業様向けに、特別料金での法人企業宿泊契約や特典をご用意しております。
          </p>
          <CSectTitle03 title="ご契約法人企業　宿泊予約" exClass="u_mb30" />
          <p className="c_sectLead u_mb0">
            ご契約法人企業様は、こちらでご契約特別料金でのご予約を承ります。
          </p>
          <div className="u_tac u_tal_sp">
            <ul className="c_noteList">
              <li>
                アカウント・パスワードをお持ちでないご契約法人企業様は、営業担当までお問合せください。
              </li>
            </ul>
          </div>
          <CBtnList
            exClass="u_mb80"
            data={[
              {
                label: 'ご契約企業様のご予約',
                color: 'bgTheme',
                link: {
                  href: 'https://rsv.ihonex.com/cgi-bin/ihonex3/houjin_check.cgi?hid=rphs_nihonbashi&amp;form=jp',
                  blank: true,
                },
              },
            ]}
          />
          <CSectTitle03 title="法人企業契約特典（一例）" exClass="u_mb30_sp" />
          <CGeneralCard03
            exClass="u_mb80"
            col={2}
            data={[
              {
                title: 'チェックアウトのご延長',
                text: (
                  <>
                    ご希望により、チェックアウトを15:00まで無料延長いたします
                    <br className="u_pc" />
                    （当日の状況により、お受けできない場合もございますので、
                    <br className="u_pc" />
                    あらかじめご了承ください。）。
                  </>
                ),
              },
              {
                title: '優先ご予約',
                text: <>優先的にご予約をお取りいたします。</>,
              },
            ]}
          />
          <CSectTitle03
            title="法人企業契約に関するお問合せ"
            exClass="u_mb30_sp"
          />
          <p className="c_sectLead">
            ご契約に関しましては、営業担当者がきめ細かくご対応させていただきます。
            <br className="u_pc" />
            以下の内容をご確認の上、フォームまたはお電話にてお問合せください。担当者よりご連絡を差しあげます。
          </p>
          <div style={{ maxWidth: '620px', margin: '0 auto' }}>
            <div className="l_borderSect02">
              <ul className="c_circleList03">
                <li>貴社名</li>
                <li>ご担当部署名</li>
                <li>ご住所</li>
                <li>お電話番号</li>
                <li>ご担当者様名</li>
                <li>年間ホテル利用泊数（目安）</li>
              </ul>
            </div>
          </div>
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: '宿泊',
              path: '/stay/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LContact03
        title="お問合せ"
        btn={{
          btn: {
            label: 'お問合せフォーム',
            link: {
              href: 'https://contact.royalparkhotels.co.jp/rph/contact?_ifbs-rph_contact_form=s1_Step1',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-3667-1111',
          subText: '（代表）',
        }}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
